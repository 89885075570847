import React, { useState } from "react";
import { AppBar, Toolbar, TextField, ListItem, Divider, themes } from "react95";
import { ThemeProvider } from "styled-components";

import SignUpModal from "./components/sign-up-modal";
import Menu from "./components/Menu";
import MemesContainer from "./containers/memes";
import { FullScreenVideo } from "./components/full-screen-video";

import { openLink } from "./utils/link";

import { AppBody, ResetStyles } from "./styles/styled";
import mahognySrc from "./assets/Mahogny_SUPER.mp4";
import "./styles/App.css";

const Memes = {
  mahogny: mahognySrc,
  glootemon:
    "https://res.cloudinary.com/gloot/video/upload/v1587384692/Loot%20Labs/Memes/glootemon_episode_1.mp4",
  photograph:
    "https://res.cloudinary.com/gloot/video/upload/v1587384692/Loot%20Labs/Memes/photograph.mp4",
};

function App() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [memeVideo, setMemeVideo] = useState("");

  return (
    <div className="App">
      <ThemeProvider theme={themes.default}>
        <ResetStyles />

        <AppBar style={{ bottom: 0, top: "auto", zIndex: "1" }}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <Menu text="Start">
              <ListItem
                onClick={() => {
                  setIsVideoPlaying(false);
                }}
              >
                Documents
              </ListItem>
              <ListItem
                onClick={() => {
                  setMemeVideo(Memes.photograph);
                  setIsVideoPlaying(true);
                }}
              >
                Photos
              </ListItem>
              <ListItem
                onClick={() =>
                  openLink("https://www.youtube.com/watch?v=V1bFr2SWP1I")
                }
              >
                Find Happiness
              </ListItem>
              <ListItem
                onClick={() => {
                  setMemeVideo(Memes.mahogny);
                  setIsVideoPlaying(true);
                }}
              >
                Help
              </ListItem>
              <ListItem
                onClick={() => {
                  setMemeVideo(Memes.glootemon);
                  setIsVideoPlaying(true);
                }}
              >
                Games
              </ListItem>
              <ListItem
                onClick={() =>
                  openLink("https://www.dropbox.com/t/G3ekEiMQXTyRA9yu")
                }
              >
                Download Dajaja
              </ListItem>
              <Divider />
              <ListItem disabled>Logout</ListItem>
            </Menu>
            <TextField
              placeholder="Search..."
              width={150}
              style={{ marginLeft: 4 }}
            />
          </Toolbar>
        </AppBar>

        <AppBody id="main-content">
          <SignUpModal />
          <FullScreenVideo isPlaying={isVideoPlaying} src={memeVideo} />

          <MemesContainer />
        </AppBody>
      </ThemeProvider>
    </div>
  );
}

export default App;
