import { Window } from 'react95';

import styled from 'styled-components';
export const StyledWindow = styled(Window)`
  width: 60vw;
  max-width: 700px;
  min-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;
export const MainView = styled.div`
  min-height: 225px;
  padding: 10px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #444;
  border-right: 2px solid #444;
`;

export const ButtonSetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 2px;
`;

export const FlexItem = styled.div`
  display: flex;
  min-height: 40vh;
  flex-direction: ${props => props.column && 'column'};
`;
