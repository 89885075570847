import React from 'react';
import { List, Button } from 'react95';
export default function Menu({ text, children, align }) {
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {open && (
        <List horizontalAlign="left" verticalAlign={align || 'top'} open={open} onClick={handleClose}>
          {children}
        </List>
      )}
      <Button onClick={handleClick} active={open} style={{ fontWeight: 'bold' }}>
        {text}
      </Button>
    </div>
  );
}
