import React from 'react';
import MemeImage from '../components/memeImage';

const MemesContainer = () => {
  return (
    <>
      <MemeImage
        bottom="10px"
        right="10px"
        src="https://i.gifer.com/origin/c6/c6afab251a20e6d0eb80b983450bc66e_w200.gif"
      />
      <MemeImage
        bottom="20px"
        left="20px"
        tilted={10}
        style={{ width: '200px' }}
        src="https://www.askideas.com/media/26/Funny-Bill-Gates-Lay-Down-On-Monitor.jpg"
      />
    </>
  );
};
export default MemesContainer;
