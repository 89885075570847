import React from 'react';
import styled, { css } from 'styled-components';

const StyledImage = styled.img`
  object-fit: contain;
  ${props =>
    props.tilted &&
    css`
      transform: ${`rotate(${props.tilted}deg);`};
    `}
`;
const MemeImage = ({ top, left, right, bottom, src, tilted, style }) => {
  return (
    <StyledImage
      tilted={tilted}
      style={{ position: 'absolute', top, left, right, bottom, ...style }}
      src={src}
    ></StyledImage>
  );
};
export default MemeImage;
