import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { usePrevious } from "../utils/hooks/usePrevious";

const StyledVideo = styled.video`
  min-height: calc(100vh - 45px);
  position: absolute;
  width: 100vw;
  /* display:  */
  visibility: ${(props) => (props.showVideo ? null : "hidden")};
  /* z-index: 2; */
`;

export function FullScreenVideo({ isPlaying, src }) {
  const [showVideo, setShowVideo] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const lastSrc = usePrevious(src);
  const video = useRef();

  useEffect(() => {
    if (isPlaying) {
      video.current.load();
      video.current.play();
    } else {
      video.current.pause();
      setShowVideo(false);
      setCanPlay(false);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (lastSrc && src && lastSrc !== src) {
      video.current.pause();
      setShowVideo(false);
      setCanPlay(false);
      video.current.load();
      video.current.play();
    }
  }, [src, lastSrc]);

  useEffect(() => {
    video.current.addEventListener("canplaythrough", () => {
      setCanPlay(true);
    });
    video.current.addEventListener("play", () => {
      setTimeout(() => {
        setShowVideo(true);
      }, 700);
    });
  }, []);

  return (
    <StyledVideo
      ref={video}
      showVideo={showVideo} // muted={!showVideo}
      preload="auto"
      autoPlay={showVideo}
      loop
    >
      <source src={src} type="video/mp4" />
    </StyledVideo>
  );
}
