import { openLink } from './link';

export const openOogaBaby = () => openLink('https://www.youtube.com/watch?v=oHSuyTrKwdg');

// export const revealBackground = () => {
//   const mainContent = document.getElementById('main-content');
//   mainContent.classList.add('mahogny');
// };
// export const removeBackground = () => {
//   const mainContent = document.getElementById('main-content');
//   mainContent.classList.remove('mahogny');
// };
