import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { reset } from 'react95';

export const ResetStyles = createGlobalStyle`
  ${reset}
`;
export const AppBody = styled.div`
  position: relative;
  background-color: #008083;
  min-height: calc(100vh - 45px);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;
