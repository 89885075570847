import React, { useState } from "react";
import styled from "styled-components";
import { WindowContent, WindowHeader, Toolbar, Button, Tooltip } from "react95";
import { ButtonSetWrapper, FlexItem, MainView, StyledWindow } from "./styled";
import { openOogaBaby } from "../../utils/easter-eggs";
import { openLink } from "../../utils/link";

const TooltipButton = ({ onClick, tooltip, children }) => (
  <Tooltip text={tooltip}>
    <Button onClick={onClick}>{children}</Button>
  </Tooltip>
);

const StyledList = styled.ol`
  padding-left: 25px;
  li {
    list-style-type: disc;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
const StyledLink = styled.span`
  color: blue;
  cursor: pointer;
  :hover {
    color: darkblue;
  }
`;
const StyledStrongText = styled.span`
  font-weight: 600;
`;
const SignUpModal = () => {
  const firstPage = 1;
  const lastPage = 5;
  const [currentPage, setCurrentPage] = useState(firstPage);
  const nextPage = () => {
    if (currentPage !== lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage !== firstPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  const goToLootLabsSignUp = () => {
    openLink(
      "https://docs.google.com/spreadsheets/d/1POFt_X-CU3IA0zGuvt5_LOsAGx90F4TOWNnxpHa5-9s/edit#gid=0"
    );
  };

  const Step = ({ page }) => {
    switch (page) {
      case 1:
        return (
          <>
            You've been invited to take part in a great and wonderous event,
            Loot Labs! <br /> Please Click Next
          </>
        );
      case 2:
        return (
          <>
            <h1>What is Loot Labs?</h1>
            It's two days of pure hacking and working on whatever new idea that
            you think would bring value to our company—technical or otherwise!
          </>
        );
      case 3:
        return (
          <>
            <h1>How does it work?</h1>
            For 2 days, step outside of your usual role to work on a project or
            idea that interests you. Join or bring together a multifunctional
            team to work on your project. Want push notifications that let you
            know when there’s no queue for the office bathrooms? Or a Check-in
            App for GLL tournaments? Grab some devs, designers, marketeers, etc,
            and make it happen.
          </>
        );
      case 4:
        return (
          <div style={{ textAlign: "left" }}>
            <div>Some Examples of things to work on</div>
            <StyledList>
              <li>Make nice graphics for Gnog (That's what she said)</li>
              <li>New ways of doing automatic financial audits</li>
              <li>Fortnite and Marques Brownlee</li>
              <li>Implementing pet daycare in the basement</li>
              <li>Working out a new feedback model for employees</li>
              <li>Trying out a sick payment flow</li>
              <li>Office Space Improvements</li>
              <li>Improving the pinball machine so it doesn't get stuck</li>
              <li>
                New microservice running in the cloud using machine learning and
                protobuff
              </li>
              <li>
                Or whatever wacky stuff you might want to work on and show
                everyone!
              </li>
            </StyledList>
          </div>
        );
      case 5:
        return (
          <>
            So What are you waiting for? Quarantine to be over? <br />
            Press the button and add your idea to the google spreadsheet or join
            someone else’s project. Find some buddies and get the ball{" "}
            <StyledLink
              onClick={() =>
                openLink("https://www.youtube.com/watch?v=dQw4w9WgXcQ")
              }
            >
              rolling
            </StyledLink>{" "}
            together!
          </>
        );
      default:
        return <>Woops how did you end up here?</>;
    }
  };

  return (
    <StyledWindow>
      <FlexItem column>
        <WindowHeader
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: "100%", whiteSpace: "nowrap" }}>
            Loot Labs Setup
          </span>
          <Button
            style={{ marginRight: "-6px", marginTop: "1px" }}
            size={"sm"}
            square
          >
            <span style={{ fontWeight: "bold", transform: "translateY(-1px)" }}>
              x
            </span>
          </Button>
        </WindowHeader>
        <Toolbar>
          <Button onClick={openOogaBaby} variant="menu" size="sm">
            Help
          </Button>
        </Toolbar>
        <WindowContent style={{ height: "100%" }}>
          <MainView>
            <Step page={currentPage} />
          </MainView>
          <ButtonSetWrapper>
            {currentPage !== lastPage ? (
              <TooltipButton tooltip="Click me 🤠" onClick={nextPage}>
                Next
              </TooltipButton>
            ) : (
              <TooltipButton tooltip="Click me 🤠" onClick={goToLootLabsSignUp}>
                <StyledStrongText>Shut up and take me there!</StyledStrongText>
              </TooltipButton>
            )}
            {currentPage > firstPage && (
              <TooltipButton tooltip="Click me 🤠" onClick={previousPage}>
                Previous
              </TooltipButton>
            )}
          </ButtonSetWrapper>
        </WindowContent>
      </FlexItem>
    </StyledWindow>
  );
};
export default SignUpModal;
